.contact-input {
  border-radius: 20px;
  color: white;
  background-color: #464146;
}

.contact-input:focus {
  border-radius: 20px;
  color: white;
  background-color: #868186;
}

.contact-input::placeholder {
  color: white;
}

.contact-message {
  border-radius: 20px;
  color: white;
  background-color: #464146;
}

.contact-message::placeholder {
  color: white;
}

.contact-message:focus {
  border-radius: 20px;
  color: white;
  background-color: #868186;
}

.send-button {
  border-width: 0;
  background-color: transparent;
}
