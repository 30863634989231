.outer-order-container {
    margin-top: 50px;
}

.order-container {
  border-radius: 20px;
  width: 80%;
  color: white;
  border-color: black;
  background-color: white;
  align-self: center;
}

.restaurant-title {
  font-size: 20px;
  color: black;
  font-weight: 800;
}

.email-title {
  font-size: 16px;
  color: black;
}

.info-label {
  font-size: 16px;
  color: black;
}

.info-text {
  font-size: 16px;
  color: black;
  font-weight: 800;
}

.divider {
  border: solid;
  border-color: black;
  border-width: thin;
}

.headshot {
  height: 200px;
  width: 200px;
  border-radius: 25px;
  padding: 10px;
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}
