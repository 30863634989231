a {
    text-decoration: none !important;
}

* {
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box; /* Firefox, other Gecko */
    box-sizing: border-box; /* Opera/IE 8+ */
}

html, body, .app-body, .main {
  padding: 0;
  margin: 0;
}

body {
    color: #325050;
    background: #fff;
    font-family: Edmondsans;
    text-align: center;
    display: inline-block;
    width: 100%;
}

.register-background {
  background-color: black;
  padding-bottom: 50vh;
}

.card-background {
  background-color: #A9A9A9;
  border-radius: 20px;
}

.card-header-background  {
  background-color: black;
}

.register-title {
  font-size: 24px;
  font-weight: bold;
}

.href {
 cursor: pointer;
 color: black;
}

.href:hover {
 color: black;
}

.footer-button {
  background:none;
  border:none;
  font-size:1em;
  color:blue;
}

/* form starting stylings ------------------------------- */
.group        {
  position: relative;
}

.state-select {
  font-size: 18px;
  padding: 10px 10px 10px 5px;
  display: block;
  width: 100%;
  border: none;
  border-bottom: 1px solid #757575;
  background-color: #f8f8f8;
  color: #999;
  border-radius: 0;
  height: 100%;
}

input {
  font-size: 18px;
  padding: 10px 10px 10px 5px;
  display: block;
  width: 100%;
  border: none;
  border-bottom: 1px solid #757575;
  background-color: #f8f8f8;
}
input:focus     { outline:none; }

/* LABEL ======================================= */
label {
  color: #999;
  font-size: 18px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 10px;
  transition: 0.3s ease all;
}

/* BOTTOM BARS ================================= */
.bar  { position: relative; display: block; width: 100%; }
.bar:before, .bar:after   {
  content:'';
  height: 3px;
  width: 0;
  bottom: 0;
  position: absolute;
  background: #28a745;
  transition: 0.2s ease all;
}
.bar:before {
  left: 50%;
}
.bar:after {
  right: 50%;
}

/* active state */
input:focus ~ .bar:before, input:focus ~ .bar:after {
  width: 50%;
}

/* HIGHLIGHTER ================================== */
.highlight {
  position: absolute;
  height: 60%;
  width: 8%;
  top: 100%;
  left: 0;
  pointer-events: none;
  opacity: 0.5;
}

/* active state */
input:focus ~ .highlight {
  animation: inputHighlighter 0.3s ease;
}

/* ANIMATIONS ================ */
@keyframes inputHighlighter {
  from  { background: #28a745; }
  to    { width:0; background:transparent; }
}

/* active state */
input:focus ~ label, input.used ~ label {
  top: -20px;
  font-size: 14px;
  color: #28a745;
}

.inputLabel {
  top: -14px;
  font-size: 14px;
  color: #28a745;
}

.otherLabel {
  font-size: 14px;
  color: #28a745;
}
