.home-container {
  background: -webkit-linear-gradient(black, #DCDCDC 75%, #787878);
}

.home-headline-text {
  font-size: 40px;
  color: #0dff4b;
  font-weight: bold;
  width: 85%;
  text-align: center;
  align-self: center;
  margin-top: 50px;
}

@media only screen and (max-width: 991px) {
  .center {
    text-align: center;
    width: 100%;
  }
}

.hero-image {
  max-height: 560px;
  max-width: 800px;
  width: 100%;
  height: auto;
  flex: 1;
}

.section-container {
  background: -webkit-linear-gradient(#A9A9A9, #fff);
}

.section-headline {
  font-size: 40px;
  color: #3d363b;
  font-weight: bold;
  width: 85%;
  margin-top: 50px;
}

.section-body-text {
  font-size: 18px;
  color: #3d363b;
  width: 85%;
}

.mission-image {
  max-height: 400px;
  max-width: 1200px;
  width: 100%;
  height: auto;
  flex: 1;
}

.fact-image {
  max-height: 175px;
  max-width: 175px;
  width: 100%;
  height: auto;
  flex: 1;
}

.fact-body-text {
  font-size: 18px;
  color: #3d363b;
  width: 350px;
}

@media only screen and (max-width: 767px) {
  .responsive-container {
    flex-direction: column;
  }

  .serving-body-text {
    font-size: 18px;
    color: #3d363b;
    width: 350px;
    text-align: center;
  }

  .download-android {
    height: 60px;
    width: 153px;
  }

  .download-ios {
    height: 45px;
    width: 135px;
  }
}

@media only screen and (min-width: 767px) {
  .responsive-container {
    margin-bottom: 20px;
    margin-left: 20px;
    margin-right: 20px;
  }

  .serving-body-text {
    font-size: 18px;
    color: #3d363b;
    width: 350px;
    text-align: left;
  }

  .download-android {
    height: 90px;
    width: 230px;
  }

  .download-ios {
    height: 60px;
    width: 180px;
  }

}



.facebook {
  color: #3b5998;
}

.twitter {
  color: #55acee;
}

.instagram {
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
  border-radius: 20px;
  padding: 12px;
  color: #fff;
}

.social-text {
  font-size: 26px;
  color: #3d363b;
  width: 800px;
  margin-top: 20px;
}

.serving-headline-text {
  font-size: 28px;
  color: #3d363b;
  font-weight: bold;
}

.serving-body-text {
  font-size: 18px;
  color: #3d363b;
  width: 350px;
  text-align: left;
}
